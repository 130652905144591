import React from 'react';
import './App.css';
import ReservationForm from './ReservationForm';

function App() {
  return (
    <div className="App">
      <h1>Formular de rezervare</h1>
      <ReservationForm />
    </div>
  );
}

export default App;
