import React, { useState } from 'react';
import './ReservationForm.css'; // Importă fișierul CSS

const ReservationForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    date: '',
    time: '',
    guests: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const submitForm = (e) => {
    e.preventDefault();
    console.log(formData);
    alert('Rezervarea a fost trimisă cu succes!');
  };

  switch (step) {
    case 1:
      return (
        <div className="form-container">
          <h2>Pasul 1: Informații personale</h2>
          <form>
            <label>Nume:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
            <div className="button-group">
              <button type="button" onClick={nextStep}>
                Următorul pas
              </button>
            </div>
          </form>
        </div>
      );
    case 2:
      return (
        <div className="form-container">
          <h2>Pasul 2: Detalii rezervare</h2>
          <form>
            <label>Data rezervării:</label>
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleInputChange}
            />
            <label>Ora rezervării:</label>
            <input
              type="time"
              name="time"
              value={formData.time}
              onChange={handleInputChange}
            />
            <label>Număr de persoane:</label>
            <input
              type="number"
              name="guests"
              value={formData.guests}
              onChange={handleInputChange}
            />
            <div className="button-group">
              <button type="button" onClick={prevStep}>
                Pasul anterior
              </button>
              <button type="button" onClick={nextStep}>
                Următorul pas
              </button>
            </div>
          </form>
        </div>
      );
    case 3:
      return (
        <div className="form-container">
          <h2>Pasul 3: Confirmare</h2>
          <div className="confirmation">
            <p><strong>Nume:</strong> {formData.name}</p>
            <p><strong>Email:</strong> {formData.email}</p>
            <p><strong>Data rezervării:</strong> {formData.date}</p>
            <p><strong>Ora rezervării:</strong> {formData.time}</p>
            <p><strong>Număr de persoane:</strong> {formData.guests}</p>
          </div>
          <div className="button-group">
            <button type="button" onClick={prevStep}>
              Pasul anterior
            </button>
            <button type="submit" onClick={submitForm}>
              Confirmă rezervarea
            </button>
          </div>
        </div>
      );
    default:
      return <div>Formular completat!</div>;
  }
};

export default ReservationForm;
